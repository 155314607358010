import UnderPrepare from "../components/underPrepare.jsx"
import ServicesPage from "../assets/images/ServicesPage.png"

export default function advancedServices() {

    return (
        <div style={{
            backgroundImage: `url(${ServicesPage})`,
            backgroundRepeat: 'no-repeat',
            width: '1064px',
            height: '600px',
            backgroundSize: 'contain',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backgroundBlendMode: 'lighten'
        }} className="d-flex flex-column justify-content-center mx-auto">
            <UnderPrepare />

        </div>
    );
};
