import SaudiRiyadl from "../../assets/icons/SaudiRiyal-BLK.svg"
import SaudiRiyadlWHT from "../../assets/icons/SaudiRiyal-WHT.svg"

export function RiyalImgBLK() {
    return (
        <img src={SaudiRiyadl} alt="Riyal Sympol" style={{ width: 15 }} />
    )
}
export function RiyalImgWHT() {
    return (
        <img src={SaudiRiyadlWHT} alt="Riyal Sympol" style={{ width: 15 }} />
    )
}