import { RiyalImgBLK } from "../../components/common/RiyalImg";
import { handlePDFClick } from "../../utilities/handleFunctions/HandlePDFOpen"

function UserReqDetials({ orderId, numberOfForms, language, orderDescription, subServices, orderStatus, serviceName, offerFile, customerFile, offerPrice }) {

    return (
        <div id={`user-order-details-${new Intl.NumberFormat("en-IN", { minimumIntegerDigits: 3 }).format(orderId,)}`}
            className="modal fade bg-transparent"
            tabIndex="-1"
            aria-hidden="true"
            aria-labelledby="orderDetailsLabel"
            data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="orderDetailsLabel">تفاصيل العرض</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <h6 className="mt-3">{serviceName}</h6>
                        <table className="table text-start ">
                            <tbody>
                                {numberOfForms > 1 && <tr>
                                    <td>عدد النماذج</td>
                                    <td>{numberOfForms}</td>
                                </tr>}
                                {language === "arabic" && <tr>
                                    <td>اللغة</td>
                                    <td>عربي</td>
                                </tr>}
                                {language === "english" && <tr>
                                    <td>اللغة</td>
                                    <td>إنجليزي</td>
                                </tr>}
                                {language === "arabic + english" && <tr>
                                    <td>اللغة</td>
                                    <td>عربي + إنجليزي</td>
                                </tr>}
                                {subServices && <tr>
                                    <td>الخدمات المطلوبة</td>
                                    <td>{subServices}</td>
                                </tr>}
                                {offerPrice && <tr>
                                    <td>تكلفة الخدمة</td>
                                    <td>{offerPrice}<RiyalImgBLK /></td>
                                </tr>}
                                <tr>
                                    <td>حالة الطلب</td>
                                    <td>{orderStatus}</td>
                                </tr>
                                <tr>
                                    <td>الملف المرفوع</td>
                                    <td >
                                        {customerFile &&
                                            <button className="btn-masar-sm btn-light-blue"
                                                value={customerFile}
                                                onClick={handlePDFClick}>
                                                ملفاتي</button>
                                        }</td>
                                </tr>
                                <tr>
                                    <td>ملف العرض</td>
                                    <td >
                                        {offerFile ?
                                            <button className="btn-masar-sm btn-dark-orange"
                                                value={offerFile}
                                                onClick={handlePDFClick}>
                                                العرض</button>
                                            :
                                            <button className="btn-masar-sm btn-light-blue"
                                                onClick={handlePDFClick} disabled>
                                                جاري العمل</button>
                                        }</td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <h6 className="mt-4">تفاصيل</h6>
                            <p>{orderDescription}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { UserReqDetials };