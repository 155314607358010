const ProfileSample = ({ id, imgSrc, imgAlt, imgCat, description }) => (
    <div className="admin-profile-img col-12 col-md-4 p-1 position-relative">
        <p className="position-absolute bottom-0 start-50 mb-4 p-1 translate-middle-x bg-white w-75 text-center rounded">{imgCat}</p>
        <img crossOrigin="anonymous"
            src={imgSrc ? imgSrc : "/images/Profile-Sample0.jpg"}
            alt={imgAlt}
            className="rounded-4 w-100 border"
        />
    </div>
);

export default ProfileSample;