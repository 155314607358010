import { useNavigate } from 'react-router-dom';

export default function LibrarySideList({ allBooksCategories, selectedCategory, selectedBookId, setSelectedCategory, setSelectedBookId, setCurrentPage, itemCount }) {
    const navigate = useNavigate();

    return (
        <ul className={`d-none d-md-block list-group col-6 ${selectedBookId ? "col-md-12 mb-3 text-center" : "col-md-3"} pt-2 h-100`}>
            {selectedBookId ?
                <li className={`list-group-item list-group-item-action ps-2 bg-light-gray`}
                    onClick={() => (setSelectedCategory(null),
                        setSelectedBookId(null),
                        navigate(`/masarLibrary`))}>
                    كل الكتب
                </li>
                :
                <>
                    <li className={`list-group-item list-group-item-action ps-2 bg-light-gray`}
                        onClick={() => (setSelectedCategory(null),
                            setSelectedBookId(null),
                            navigate(`/masarLibrary`))}>
                        كل الكتب
                        <span className="badge badge-secondary">
                            {selectedCategory === "" || selectedCategory === null ? itemCount : ""}
                        </span>

                        {/* .filter(category => category.en !== "Children") */}
                    </li>
                    {allBooksCategories
                        .map((category, index) => (
                            <li key={index} className={`list-group-item list-group-item-action ps-2 ${selectedCategory === category.en ? 'active' : ''}`}
                                onClick={() => (setSelectedCategory(category.en), setCurrentPage(1)
                                    // navigate(`/masarLibrary?type=${category.en}`)
                                )}>
                                {category.ar}
                                <span className="badge badge-secondary">
                                    {selectedCategory === category.en ? itemCount : ""}
                                </span>
                            </li>
                        ))}
                </>}
        </ul>
    )
}