import './sideList.css';

export default function ProfileSideList({ allProfilesCategories, selectedCategory, setSelectedCategory, setCurrentPage, itemCount }) {

    return (
        <ul className="list-group col-3 col-md-2 pt-2 sticky-top h-100">
            <li className={`list-group-item list-group-item-action ps-2 bg-light-gray`}
                onClick={() => setSelectedCategory(null)}>
                عرض الكل
                <span className="badge badge-secondary">
                    {selectedCategory === null ? itemCount : ""}
                </span>
            </li>
            {allProfilesCategories.map((category, index) => (
                <li key={index} className={`list-group-item list-group-item-action ps-2 ${selectedCategory === category.en ? 'active' : ''}`}
                    onClick={() => (setSelectedCategory(category.en), setCurrentPage(1))}>
                    {category.ar}
                    <span className="badge badge-light">{selectedCategory === category.en ? itemCount : ""}</span>
                </li>
            ))}
        </ul>
    )
}