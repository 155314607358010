import { allBooksCategories } from "../data/CategoriesData"
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
// import LibraryBook from "./libraryBook";
import RequestAddBook from "../features/library/RequestAddBook";
import Popup from 'reactjs-popup';
// import { apiFetch } from '../helpers/apiFetch';
import Pagination from "../utilities/Pagination";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import LibrarySideList from "../features/library/components/LibrarySideList";
import RiyalImg, { RiyalImgWHT } from "../components/common/RiyalImg";

export default function MasarLibrary() {
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedBookId, setSelectedBookId] = useState("");
    const [libraryBooks, setLibraryBooks] = useState("");
    // const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLogin, setIsLogin] = useState(false);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [sortState, setSortState] = useState("sortNone");
    const categoryType = useLocation()

    // const queryParams = new URLSearchParams(categoryType.search);
    // const type = queryParams.get('type');

    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    };


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // const zeroQTY = libraryBooks?.data?.result.filter(item =>
    //     item.quantity != 0);


    const filteredBooksData = libraryBooks?.data?.result.filter(item =>
        item.title.includes(searchQuery) ||
        item.author.includes(searchQuery) ||
        item.publishedDate.toString().includes(searchQuery));


    const listCategory = libraryBooks?.data?.result.map((item) => {
        return { type: item.category?.type };
    });

    console.log(listCategory);

    const totalPages = libraryBooks?.data?.paginationInfo.totalPagesCount;
    const itemCount = libraryBooks?.data?.paginationInfo.totalCount;

    const sortQTY = (a) => (a.quantity > 0)
    const sortNone = (a, b) => (a.title < b.title ? -1 : 1)
    const priceAscending = (a, b) => (a.price < b.price ? -1 : 1)
    const priceDescending = (a, b) => (a.price > b.price ? -1 : 1)

    // console.log(itemCount);


    useEffect(() => {
        async function fetchLibrary() {
            try {
                const booksData = await fetch(`${process.env.REACT_APP_MASAR_API_URL}/library/books?${searchQuery ? "" : `${selectedCategory ? `type=${selectedCategory}&page=${currentPage}&limit=20` : `page=${currentPage}&limit=18`}`}`, {
                    method: "GET",
                    headers: {
                        "accept": "*/*"
                    },
                    body: JSON.stringify()
                });

                const responseData = await booksData.json();

                setLibraryBooks(responseData);
                // setSelectedCategory(type)

            } catch (err) {
                setError("هناك مشكلة في جلب البيانات");
            }
        }

        fetchLibrary();
    }, [currentPage, selectedCategory, searchQuery]);

    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!libraryBooks) {
        return <div className="loader"></div>;
    }

    return (
        <div className="container mt-4">

            <div className="d-flex flex-wrap flex-row flex-md-row align-items-center">

                <h3 className="c-darkOrange col-12 col-md-3">مكتبة مسار</h3>

                <input
                    name="queryBooks"
                    className="form-control-sm border my-2 col-8 col-md-4"
                    type="search"
                    placeholder={
                        selectedCategory
                            ? `البحث في تصنيف ${allBooksCategories.find(category => category.en === selectedCategory)?.ar || ""
                            }`
                            : `البحث باسم المؤلف / الكتاب / سنة النشر`
                    }
                    value={searchQuery}
                    onChange={handleSearchChange} />
                <div className="col-4 col-md-2 px-2">
                    <select id="sortBooks" className="form-select form-select-sm"
                        onChange={e => setSortState(e.target.value)}>
                        <option value="sortNone">ترتيب بالأسم</option>
                        <option value="priceAscending">السعر تصاعدي</option>
                        <option value="priceDescending">السعر تنازلي</option>
                    </select>
                </div>
                {Cookies.get('token') ?
                    <Popup trigger={<button className="btn btn-masar btn-light-blue col-8 col-md-1 mb-2 mx-auto">إضافة كتاب</button>} position="bottom left" modal>
                        <RequestAddBook />
                    </Popup> : ""}
            </div>

            <div className="d-flex flex-wrap justify-content-center">
                <select className="form-select d-md-none" value={selectedCategory}
                    onChange={handleChange}>
                    <option value="">كل الكتب</option>
                    {allBooksCategories.map((category, index) => (
                        <option key={index} value={category.en} >
                            {category.ar}
                        </option>
                    ))}
                </select>

                <LibrarySideList
                    allBooksCategories={allBooksCategories}
                    selectedCategory={selectedCategory}
                    selectedBookId={selectedBookId}
                    setSelectedCategory={setSelectedCategory}
                    setSelectedBookId={setSelectedBookId}
                    setCurrentPage={setCurrentPage}
                    itemCount={itemCount}
                />
                {/* {selectedBookId ?
                    <LibraryBook bookSetId={selectedBookId} setSelectedCategory={setSelectedCategory} /> : */}
                {/* <div className="d-flex flex-wrap flex-row align-content-start justify-content-center col-11 col-md-9 pt-1 ">

                    {(searchQuery ?
                        (filteredBooksData)
                        : (libraryBooks?.data?.result))
                        .sort(
                            sortState === "sortNone" ? sortNone :
                                (sortState === "priceAscending" ? priceAscending :
                                    (sortState === "priceDescending" ? priceDescending : ""))
                        )
                        .map((item) => (
                            <div
                                key={item.id}
                                // className="book-container col-12 col-sm-4 col-lg-3 col-xxl-2 my-2"
                                className="book-container col-6 col-sm-4 col-xl-3 col-xxl-2 my-2 position-relative"
                                onClick={() => navigate(`/masarLibrary/${item.id}`)}
                            // onClick={() => handleBookClick(item.id)}
                            >
                                {sortState !== "sortNone" && <span className="text-white position-absolute top-25 end-0 badge rounded-pill bg-info">
                                    {item.price}  <RiyalImgWHT />
                                    <span className="visually-hidden">unread messages</span>
                                </span>}
                                <Link className="text-decoration-none text-black">
                                    <img
                                        crossOrigin="anonymous"
                                        // key={item.id}
                                        // to remove (!) when image of book allowed //
                                        src={item.thumbnail ? item.thumbnail : "../images/BookSample-1.png"}
                                        alt={`book-id-${item.id}`}
                                        className="rounded-2" />
                                    <div className="book-title p-1 rounded">
                                        <p>{item.title}</p>
                                    </div>
                                </Link>
                            </div>
                        )

                        )}

                </div> */}
                <div className="d-flex flex-wrap flex-row align-content-start justify-content-center col-11 col-md-9 pt-1 ">

                    {(searchQuery ?
                        (filteredBooksData)
                        : (libraryBooks?.data?.result)).sort(
                            sortState === "sortNone" ? sortNone :
                                (sortState === "priceAscending" ? priceAscending :
                                    (sortState === "priceDescending" ? priceDescending : ""))
                        )
                        .map((item) => (
                            item.quantity > 0 ?
                                <div
                                    key={item.id}
                                    // className="book-container col-12 col-sm-4 col-lg-3 col-xxl-2 my-2"
                                    className="book-container col-6 col-sm-4 col-xl-3 col-xxl-2 my-2 position-relative"
                                    onClick={() => navigate(`/masarLibrary/${item.id}`)}
                                // onClick={() => handleBookClick(item.id)}
                                >
                                    {sortState !== "sortNone" && <span className="text-white position-absolute top-25 end-0 badge rounded-pill bg-info">
                                        {item.price}  <RiyalImgWHT />
                                        <span className="visually-hidden">unread messages</span>
                                    </span>}
                                    <Link className="text-decoration-none text-black">
                                        <img
                                            crossOrigin="anonymous"
                                            // key={item.id}
                                            // to remove (!) when image of book allowed //
                                            src={item.thumbnail ? item.thumbnail : "../images/BookSample-1.png"}
                                            alt={`book-id-${item.id}`}
                                            className="rounded-2" />
                                        <div className="book-title p-1 rounded">
                                            <p>{item.title}</p>
                                        </div>
                                    </Link>
                                </div> : ""
                        ))}

                </div>
                <div className="col-4">

                    {totalPages > 1 &&
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages} />
                    }
                </div>

                {/* } */}
            </div>

        </div>
    );
}
