import { allProfilesCategories } from "../data/CategoriesData"
import Pagination from "../utilities/Pagination";
import React, { useState, useEffect } from 'react';
import { apiFetch } from '../hooks/apiFetch';
import ProfileSample from "../features/profile/ProfileSample";
import ProfileSideList from "../features/profile/components/ProfileSideList";

export default function Profile() {
    const [profileWorks, setProfileWorks] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState(null);

    const filteredProfileWorksData = selectedCategory === null ? profileWorks?.data?.result : profileWorks?.data?.result.filter(item => item.service.name === selectedCategory);
    const totalPages = profileWorks?.data?.paginationInfo.totalPagesCount;
    const totalItemCount = profileWorks?.data?.paginationInfo.totalCount;

    useEffect(() => {
        async function fetchProfileWorks() {
            try {
                const works = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/works?${selectedCategory ? `service=${selectedCategory}&` : ""}page=${currentPage}&limit=12`);

                setProfileWorks(works);

            } catch (err) {
                setError("هناك مشكلة في جلب البيانات");
            }
        }

        fetchProfileWorks();
    }, [currentPage, selectedCategory]);

    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!profileWorks) {
        return <div className="loader"></div>;
    }
    // console.log(profileWorks);

    return (
        <div className="container mt-4">
            <h3 className="c-darkOrange col-12">من أعمالنا</h3>
            <div className="d-flex flex-wrap justify-content-between">
                <ProfileSideList
                    allProfilesCategories={allProfilesCategories}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    setCurrentPage={setCurrentPage}
                    itemCount={totalItemCount}
                />
                <div className="d-flex flex-wrap justify-content-evenly col-8 col-md-9 pt-1 align-content-start">
                    {filteredProfileWorksData.map((item) => (
                        <ProfileSample
                            key={item.id}
                            id={item.id}
                            description={item.description}
                            imgSrc={item.thumbnail}
                            imgAlt={`profileWorks_${item.id}`}
                            imgCat={allProfilesCategories.map(cat =>
                                cat.en === item.service?.name ? cat.ar : "")
                            } />
                    ))}
                </div>
            </div>
            <div className="col-12">
                {totalPages > 1 &&
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages} />
                }
            </div>
        </div>
    );
}